import { ENV } from "./env";

// ルール:
// フィーチャーフラグの使用箇所を明確にするため、機能名やそれに準ずる命名をフラグ名として使用する
// (オプション) 情報を追いやすくするため、タスクチケットのリンクを添付する

const developmentNew = "development-new";
const stagingNew = "staging-new";
// const productionNew = "production-new";

export const isChangeOfConsultationHoursEnabled = () => {
  return [developmentNew].includes(ENV);
};

// web push通知の有効
export const isNotificationEnabled = () => {
  return [developmentNew, stagingNew].includes(ENV);
};

// https://www.notion.so/909badf1b4f04099b600cb599276806f?pvs=4
export const isQualificationConfirmationEnabled = () => {
  return [developmentNew].includes(ENV);
};

// https://www.notion.so/413-3MB-5MB-502-8d47c6f0b10e48fe8d7bee8f60e2c3fa
export const isLimitOfFileSizeEnabled = () => {
  return [developmentNew, stagingNew].includes(ENV);
};

// https://www.notion.so/UI-7068c1a5068b45a886652e4ca03298f2
export const isDisplayCancelInfoEnabled = () => {
  return [developmentNew, stagingNew].includes(ENV);
}

// TODO: https://www.notion.so/119e6cf8d068806a8f31f5bd745be08c?pvs=4 v1.15にて解消
export const isDisplayValidationPrefectureEnabled = () => {
  return [developmentNew, stagingNew].includes(ENV);
}

// https://www.notion.so/a88b9fa2665f4c73a01bd11102516d9f?v=45345f5ec03e4ea9977be48801cf4c3a&p=112e6cf8d068809581bece4482f8b4cd&pm=s
export const isBlurSwitchEnabled = () => {
  console.log(ENV)
  if(!ENV) {
    return true;
  }
  return [developmentNew, stagingNew].includes(ENV);
}

